import * as React from "react";
import styled from "styled-components";
import { theme } from "../../components/theme";
import Layout from "../../components/Layout";
import { Helmet } from "react-helmet";
import { Meta } from "../../components/Meta";
import { Link } from "gatsby";

interface IGPXPageProps {}

const Tools: React.FC<IGPXPageProps> = ({}) => {
  return (
    <Layout>
      <Meta />
      <Helmet>
        <title>kame's blog - Tools</title>
        <meta name="robots" content="noindex" />
      </Helmet>

      <Container>
        <h1 style={{ width: "100%", textAlign: "center" }}>tools</h1>

        <Body>
          <Link to="/tools/gpx">GPX</Link>
        </Body>
      </Container>
    </Layout>
  );
};

export default Tools;

const Container = styled.div`
  margin: 0 auto;
  padding: 0 12px;

  width: 100vw;
  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    width: ${theme.width.body};
    padding: 0;
  }
`;

const Body = styled.div`
  color: #000;
`;
